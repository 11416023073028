// http://flexbox.buildwithreact.com/
import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import {minWidth} from '../../themes/default' // max

const FlexBox = (props) => 
    <Flex className = {props.hasClass}
      flexDirection = {props.flexDirection}
      flexWrap = {props.flexWrap}
      justifyContent = {props.justifyContent}
      alignItems = {props.alignItems}
      alignContent = {props.alignContent}
      padding = {props.padding}
    >
      {props.children}
    </Flex>
 

//props
FlexBox.defaultProps = {
  hasClass: '',
  flexDirection: '',
  flexWrap: '',
  justifyContent: '',
  alignItems: '',
  alignContent: '',
  padding: '',

}

FlexBox.propTypes = {
  /** URL as string and required. */

  hasClass: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  alignContent: PropTypes.string,
  padding: PropTypes.string,
}

export default FlexBox


//styles

const Flex = styled.div`
  display:flex; 
  flex-direction: column;
  flex-wrap: ${props => props.flexWrap || 'nowrap'} ;
  justify-content: ${props => props.justifyContent || 'center'} ;
  align-items: ${props => props.alignItems || 'center'} ;
  align-content: ${props => props.alignContent || 'stretch'};
  width:100% !important;
  max-width: ${props=> props.theme.wide.desktopPlus};
  margin: 0 auto;
  padding:${props => props.padding || '2rem 1rem'}; 
  box-sizing: border-box;

  @media ${minWidth.smallDesktop} {
    flex-direction: ${props => props.flexDirection || 'row'}
  } 
`
