// http://flexbox.buildwithreact.com/
import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

const FlexItem = (props) => 

<FlexItemStyled
  alignItems = {props.alignItems}
  flex = {props.flex}
  justifyContent = {props.justifyContent}
  width = {props.width}
  height  = {props.height}
  padding = {props.padding}
  margin = {props.margin}
  fontSize = {props.fontSize}
  textAlign = {props.textAlign}
>
  {props.children}
</FlexItemStyled>


//props
FlexItem.defaultProps = {
  alignItems: '',
  flex: '',
  justifyContent: '',
  width: '',
  height: '',
  padding: '',
  margin: '',
  fontSize: '',
  textAlign: '',
}

FlexItem.propTypes = {
  alignItems: PropTypes.string,
  flex: PropTypes.string,
  justifyContent: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.string,
  textAlign: PropTypes.string,
}

export default FlexItem


//styles 
const FlexItemStyled = styled.div`
  display: flex;
  box-sizing: border-box;

  align-items: ${props => props.alignItems || 'center'};
  flex: ${props => props.flex || '0 1 auto'}; 
  justify-content: ${props => props.justifyContent || 'center'}; 
  width: ${props => props.width}; 
  height: ${props => props.height};
  padding: ${props => props.padding}; 
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign };
`
