import React from 'react'
import NetworkInformation from 'react-network-info';

import { Link, graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import FlexBox from '../FlexBox'
import FlexItem from '../FlexItem'

import styled from 'styled-components'
import {minWidth} from '../../../themes/default' // maxWidth

const Allprofiles = (props) => 

<section style={{ textAlign: 'center' }} className="profiles">
  <Title>{props.title}</Title>

  <FlexBoxProfiles
    flexWrap = "wrap"
    padding = "0 1rem 2rem"
  >
    <StaticQuery 
      query={data}
      render = { props => {
          const {edges} = props.allWordpressWpProfiles; 
          

          return(
          <>
          {edges.map(profile => {

            // const img= profile.node.featured_media.localFile.childImageSharp.fixed

            return(
              <FlexItemProfiles 
                key={profile.node.id}>
                <Link to={`/profile/${profile.node.slug}/`}>
                  {/* profile.node.excerpt != null ?
                    <div dangerouslySetInnerHTML={{__html: profile.node.excerpt }}></div> : ''
                  */ } 
                  
                  <NetworkInformation>
                  {props => 
                    props.effectiveType !== '2g' ? (
                      profile.node.featured_media.localFile.childImageSharp.fixed != null ?
                        <Img fixed={profile.node.featured_media.localFile.childImageSharp.fixed} /> : ''
                      ) : (null)
                  }
                  </NetworkInformation>
                  <Name>
                    {profile.node.title}
                  </Name>
                </Link>
                
                {/* <SpeedImage src={profile.node.featured_media.source_url} /> */}
              </FlexItemProfiles>
            )

          }
            
          )}
          </>
          )
        }
      }
    />  
    </FlexBoxProfiles>
 </section>

export default Allprofiles

const Name = styled.h3`
  color: ${props => props.theme.color.red} !important;
  font-size: 1rem;
  margin:0 0 50px;
  padding:0;
  text-decoration: none !important;
`
const Title = styled.h2`
  margin-bottom:50px;
`

const FlexBoxProfiles = styled(FlexBox)`
  flex-direction: column;
  @media ${minWidth.tablet} {
    flex-direction: row
  } 
`

const FlexItemProfiles = styled.div`
  width:100%;
  flex: 0 1 100%;
  padding :0 1rem;
  box-sizing: border-box;

  a  {
    display: block;
    width:100%;
  }

  .gatsby-image-wrapper {
    width:100% !important;
    height:0 !important;
    padding-bottom:75%;
  }

  @media ${minWidth.tablet} {
    flex: 0 1 25%;
    a {
      display: inline
    }
    .gatsby-image-wrapper {
      width:200px !important;
      height:200px !important;
      padding-bottom:0;
    }
  } 

`


// http://content.travelkitties.com/wp-json/wp/v2/pages
const data = graphql`
  {
  allWordpressWpProfiles {
    totalCount
    edges {
      node {
        id
        wordpress_id
        title
        excerpt
        slug
        status
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fixed(width:200, height:200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }   
        }
      }
    }
  }
}
`;
