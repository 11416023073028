import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SpeedImage from "../components/utils/speedImages"
import AllProfiles from '../components/utils/Profiles/Allprofiles'
import Holder from "../components/utils/Holder";
import InnerHolder from "../components/utils/InnerHolder";
import ImageBackground from "../components/utils/ImageBackground";

import styled from 'styled-components'
import {minWidth} from '../themes/default' // maxWidth


const Aboutus = () => (
  <Layout>
    <SEO title="About FlyAirTreks" description="Planning complex trips used to be overwhelming, tedious and time-consuming. " />
    <header className="hidden">
      <h1>About Us</h1>
    </header>

    <Holder textAlign = "center">
      <InnerHolder>
        
          <Title>The AirTreks Difference</Title>
          

      </InnerHolder>

      <ImageBackground darkerby="70" />
    </Holder>

 

    <Holder>
      <InnerHolder>

      <MaxDesktop>
      <p style={{fontWeight: 'bold', textAlign: 'center', padding: '20px', 
        borderTop: '2px solid #ddd', borderBottom: '2px solid #ddd', color: 'gray'
      }}>
        Planning complex trips used to be overwhelming, tedious and time-consuming. <br />
        Fortunately, that's no longer the case. <br />
        Our knowledgeable team of Travel Planners <br />
        make it easy to plan an around the world or multi-stop world trip.
      </p>
        
      </MaxDesktop>

      <Clear />


      <Text>
        <Float className="left">
          <SpeedImage src="places.jpg" alt="Tell us the places you dream of visiting" />
        </Float>
          <TitleH3>All you have to do? Tell us the places you dream of visiting. We'll do the rest.</TitleH3>
          <p>Build a route idea using out Trip Planner tool to get matched with your personal travel planner (like Aurelie, pictured here on the Inca trail!) 
              Make sure to ask them about their own around the world trips.

              Within 24 hours, they will contact you to learn more about your trip nd design the ultimate itinerary that is 100% customized and personalized just for you.
          </p>
      </Text>
      </InnerHolder>

      <Clear />

      <InnerHolder>
      <Text>
        <Float className="right">
          <SpeedImage src="person-airport.jpg" alt="Support" />
        </Float>

        
          <p>
            When the details of your trip are exactly what you want just say the word and we will book all the flights for you at one time.
            <br /> <br />
            You will receive a one page itinerary with all your flight details that updates in real time. 
            <br /> <br />
            The best part? We stay in contact with you during your trip to ensure smooth travel.
          </p>

          <p><strong>
            Our 24/7 world-class support team are always a quick phone call or email away should there by any flight cancellations or trip interruptions.
            </strong>
          </p>
        </Text>

      </InnerHolder>

      
    </Holder>

 

    <Holder>
    
      
      <MaxDesktop>
        <Title>We are a distributed travel company with employees working from all corners of the world</Title>
        <p>Our team believes in travel so much that we officially retired our physical office. We work from all over making around-the-world travel simpler and easier. Our schooling was the open road, and we believe in putting a gap year on every resume.
        <br /> <br />
        Since 1987, AirTreks has been a leader in multi-stop international travel, especially complex routes with up to 25 stops. We believe travel is vital: our core values are making meaningful connections, embracing change, working and playing with passion, loving learning, and owning our experience.</p>
      </MaxDesktop>

      <ImageBackground  darkerby="0" />
      
    </Holder>

    


  

    

    <Holder textAlign="center">
      <InnerHolder>
        <Title>Our colleagues work from all over the world</Title>
        <SpeedImage src="about-map-1.jpg" alt="map" />
      </InnerHolder>
      
    </Holder>

    <AllProfiles title="Meet Our Expert Travel Planners" />
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default Aboutus

const Title = styled.h2`
  font-size: 2rem;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
`

const TitleH3 = styled.h3`
  font-size: 1.625rem;
`

const Text = styled.div`
  /* background: rgba(255,255,255, .7); */
  /* color: ${props => props.theme.color.dark}; */
  padding: 0.5rem 1rem;
  overflow: hidden;
`

const Float = styled.div`
  
  @media ${minWidth.smallDesktop} {
    &.left {
      float: left;
      margin-right: 18px;
      width:35%;
    }

    &.right {
      float: right;
      margin-left: 18px;
      width:35%;
    }
    &:before{
      content: "";
      clear: both;
      width:100%;
    }
  } 
  
`

const MaxDesktop = styled.div`
  max-width:960px;
  margin:0 auto;
  z-index: 5;
  position: relative;
  color: ${props => props.theme.color.dark};
`

const Clear = styled.div `
  width:100%;
  clear:both;
  height:50px;
  line-height:50px;
  overflow:hidden
`
